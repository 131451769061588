import styled, { css } from 'styled-components'
import Anchor from '../../components/ui/anchor'

export const FakeButtonWrapper = styled.div`
  position: relative;
  height: 56px;
  border-top: 1px solid #eee;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    transition: ${(props) => props.theme.transition};
    z-index: -1;

    ${(props) =>
      props.color
        ? css`
            background-color: ${props.color};
          `
        : css`
            background-color: ${props.theme.colors.theme};
          `}
  }
`

export const FakeButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-size: 16px;
  transition: ${(props) => props.theme.transition};

  svg {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 4px;
    color: ${(props) => props.theme.colors.theme};
  }

  span {
    display: inline-block;
    color: ${(props) => props.theme.colors.textDark};
    line-height: 1.1em;
  }
`

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: ${(props) => props.theme.transition};

  &:hover {
    transform: translateY(-5px);

    h4 {
      color: ${(props) => props.theme.colors.theme};
    }

    ${FakeButton} {
      &,
      span,
      svg {
        color: ${(props) => props.theme.colors.white};
      }
    }

    ${FakeButtonWrapper} {
      &::before {
        transform: translateY(0);
      }
    }
  }
`

export const CardDate = styled.div`
  height: 100%;
  min-width: 60px;
  max-width: 60px;
  padding: 20px 15px;
  background-color: ${(props) => props.theme.colors.theme};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.heading};
`

export const CardDay = styled.div`
  font-size: 26px;
  line-height: 24px;
`

export const CardMonth = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`

export const CardContent = styled.div`
  padding: 20px 30px;
  flex-grow: 2;
`

export const CardTitle = styled.h4`
  padding-bottom: 0;
  color: ${(props) => props.theme.colors.textDark};
  transition: ${(props) => props.theme.transition};
`

export const CardMeta = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.text};

  span {
    margin-right: 4px;

    &::after {
      content: '|';
      display: inline-block;
      position: relative;
      top: -2px;
      margin-left: 4px;
      font-size: 10px;
      opacity: 0.6;
    }
  }

  span:last-child,
  span:last-child:first-child {
    margin-right: 0;

    &::after {
      content: none;
    }
  }
`

export const CardLink = styled(Anchor)`
  transform: none !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
`

export const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
