import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import SectionTitle from '../../components/ui/section-title'
import { Container, Row, Col } from '../../components/ui/wrapper'
import {
  SectionWrap,
  ReadMore,
} from '../elements/box-large-image/section-home/section.style'
import { GoChevronRight } from 'react-icons/go'
import { IoIosArrowRoundForward } from 'react-icons/io'

import {
  Card,
  CardDate,
  CardDay,
  CardMonth,
  CardContent,
  CardTitle,
  CardMeta,
  CardLink,
  FakeButton,
  FakeButtonWrapper,
  CardContentWrapper,
} from './event.style.js'

const Events = ({
  title,
  path,
  buttonText,
  list,
  padding,
  small,
  limit,
  filter,
  btnStyle,
  linkStyle,
  ...rest
}) => {
  const upcomingEvents = useStaticQuery(graphql`
    query UpcomingEvents {
      allContentfulEvenement(sort: { fields: date, order: ASC }) {
        edges {
          node {
            id
            slug
            title
            day: date(locale: "nl-Nl", formatString: "DD")
            month: date(locale: "nl-Nl", formatString: "MMM")
            themes {
              id
              title
            }
          }
        }
      }
    }
  `)

  const eventList = () => {
    const allEvents = upcomingEvents.allContentfulEvenement.edges.map(
      (edge) => edge.node,
    )

    if (filter) {
      return allEvents
        .map((event) => {
          if (event.themes) {
            return event.themes.filter((theme) => theme.id === filter).length
              ? event
              : false
          }
          return null
        })
        .filter((result) => result)
        .slice(0, limit)
    } else if (list) {
      return list
    } else {
      return allEvents.slice(0, limit)
    }
  }

  return (
    <SectionWrap padding={padding} className="container-xl" {...rest}>
      <Row>
        {eventList().length > 0 && (
          <Container className="container-fluid">
            <Row>
              <Col xl={12}>
                {title && <SectionTitle title={title} align="left" />}
                {path && buttonText && (
                  <ReadMore
                    path={path}
                    title={`Ga naar de ${buttonText} overzichtpagina`}
                    aria-label={`Ga naar de ${buttonText} overzichtpagina`}
                    {...linkStyle}
                  >
                    Meer <span>{buttonText}</span>
                    <IoIosArrowRoundForward
                      className="icon"
                      role="img"
                      title={`Meer ${buttonText}`}
                    />
                  </ReadMore>
                )}

                <Row>
                  {eventList().map(
                    ({ id, slug, title, day, month, themes }) => (
                      <Col
                        key={`event-${id}`}
                        lg={6}
                        mt="40px"
                        {...(!small ? { xl: 4 } : {})}
                      >
                        <Card>
                          <CardDate>
                            <CardDay>{day}</CardDay>
                            <CardMonth>{month}</CardMonth>
                          </CardDate>

                          <CardContentWrapper>
                            <CardContent>
                              {themes && (
                                <CardMeta>
                                  {themes.map(({ title }) => (
                                    <span key={`theme-${title}`}>{title}</span>
                                  ))}
                                </CardMeta>
                              )}
                              <CardLink
                                path={`/actueel/agenda/${slug}`}
                                title={`Ga naar de '${title}' detail pagina`}
                                aria-label={`Ga naar de '${title}' detail pagina`}
                              >
                                <CardTitle>{title}</CardTitle>
                              </CardLink>
                            </CardContent>
                            <FakeButtonWrapper>
                              <FakeButton className="button" {...btnStyle}>
                                <span>Bekijk</span>
                                <GoChevronRight
                                  role="img"
                                  title="Bekijk evenement"
                                />
                              </FakeButton>
                            </FakeButtonWrapper>
                          </CardContentWrapper>
                        </Card>
                      </Col>
                    ),
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        )}
      </Row>
    </SectionWrap>
  )
}

Events.propTypes = {
  title: PropTypes.string,
  padding: PropTypes.bool,
  small: PropTypes.bool,
  list: PropTypes.array,
  filter: PropTypes.string,
  limit: PropTypes.number,
  btnStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  path: PropTypes.string,
  buttonText: PropTypes.string,
}

Events.defaultProps = {
  padding: false,
  small: false,
  limit: 3,
  btnStyle: {
    iconposition: 'right',
    icondistance: '4px',
    varient: 'texted',
    hover: 'false',
    display: 'flex',
    justify: 'center',
    alignitems: 'center',
    height: '100%',
  },
  linkStyle: {
    hover: {
      layout: 1,
    },
  },
}

export default Events
